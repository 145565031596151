<template><App :keepAliveInclude="keepAliveInclude"/></template><script>
	import {
		mapMutations,
		mapActions
	} from 'vuex'
	import {
		inputInviteCode
	} from '@/api/user'
	import {
		userShare,
		getConfig
	} from '@/api/app'
	import Cache from '@/utils/cache'
	import {
		strToParams,
		showModal,
		setTabbar
	} from '@/utils/tools'
	import debounce from '@/components/uview-ui/libs/function/debounce'
	import {
		wxMnpLogin
	} from '@/utils/login'
	export default {
		globalData: {
			navHeight: ""
		},
		onLaunch(options) {
			uni.hideTabBar({
				animation: false
			})
			// 获取配置
			this.getConfigFun()
			
			this.getUser()
			// 获取分享
			this.getShareInfo()
			//获取系统信息
			this.getSystemInfo()
			setTimeout(() => uni.onWindowResize(() => debounce(() => {
				for (const key in localStorage) {
					if (key.startsWith('custom-image-')) {
						localStorage.removeItem(key)
					}
				}
			})), 500)
			addEventListener('click', async () => {
				const silent = 'data:audio/wav;base64,UklGRjIAAABXQVZFZm10IBIAAAABAAEAQB8AAEAfAAABAAgAAABmYWN0BAAAAAAAAABkYXRhAAAAAA=='
				const audio = new Audio
				document.body.append(audio)
				const playSilent = () => {
					audio.src = silent
					audio.loop = true
					audio.play()
				}
				playSilent()
				const waitForToken = () => new Promise(resolve => {
					const store = this.$store
					const token = store.state.app.token
					if (token) {
						resolve(token)
					} else {
						const unsubscribe = store.subscribe(({ type, payload }) => {
							if (type === 'LOGIN') {
								unsubscribe()
								resolve(payload.token)
							}
						})
					}
				})
				const token = await waitForToken()
				const ws = new WebSocket(`wss://${location.host}/event/${token}`)
				ws.addEventListener('message', event => {
					const payload = JSON.parse(event.data)
					if (payload.event === 'voice') {
						const url = new URL('https://dds.dui.ai/runtime/v1/synthesize?voiceId=gdfanf_natong&speed=0.75&audioType=mp3')
						url.searchParams.set('text', payload.text)
						audio.src = url
						audio.loop = false
						audio.addEventListener('ended', playSilent, { once: true })
						audio.play()
					}
				})
			}, { once: true })
		},
		onShow() {
			const store = this.$store
			navigator.geolocation.getCurrentPosition(data => {
				const { coords } = data
				store.commit('SETGEOLOCATION', {
					state: '',
					lon: coords.longitude,
					lat: coords.latitude
				})
			}, () => store.commit('SETGEOLOCATION', { state: '定位失败' }))
		},
		onHide: function() {
			console.log('App Hide')
		},
		onResize() {
			console.log('asthonsb')
		},
		methods: {
			...mapMutations(['SETCONFIG']),
			...mapActions(['getUser']),
			getSystemInfo() {
				uni.getSystemInfo({
					success: res => {
						let {
							statusBarHeight,
							platform
						} = res;
						let navHeight;

						if (platform == 'ios' || platform == 'devtools') {
							navHeight = statusBarHeight + 44;
						} else {
							navHeight = statusBarHeight + 48;
						}
						this.globalData.navHeight = navHeight;

					},
					fail(err) {
						console.log(err);
					}

				});
			},
			async getShareInfo() {
				const {
					code,
					data
				} = await userShare()
				if (code == 1) {
					Cache.set('shareInfo', data)
				}

			},
			async getConfigFun() {
				try{
					const {
						code,
						data
					} = await getConfig()
					if (code == 1) {
						this.SETCONFIG(data)
						setTabbar()
						
					}
				}catch(e){
					uni.showTabBar()
				}
				
			}
		}
	}
</script>

<style lang='scss'>
	@import 'styles/base.scss';
	/*每个页面公共css */
	@import "components/uview-ui/index.scss";
</style>