import {
	silentLogin
} from '@/api/app';
import {
	isWeixinClient,
	currentPage,
	trottle,
	tabbarList
} from './tools'
import store from '@/store'
import Cache from './cache'
import {
	BACK_URL
} from '@/config/cachekey'
import wechath5 from './wechath5'
import {
	inputInviteCode
} from '@/api/user'


// 获取登录凭证（code）

export function getWxCode() {
	return new Promise((resolve, reject) => {
		uni.login({
			success(res) {
				resolve(res.code);
			},

			fail(res) {
				reject(res);
			}

		});
	});
}
//小程序获取用户信息
export function getUserProfile() {
	return new Promise((resolve, reject) => {
		uni.getUserProfile({
			desc: '获取用户信息，完善用户资料 ',
			success: (res) => {
				resolve(res);
			},
			fail(res) {}

		})
	})

}

export const wxMnpLogin = () => {}

export const toLogin = trottle(_toLogin, 1000)
// 去登录
function _toLogin() {






	

}
